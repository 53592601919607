import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private screenWidthSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private isMobileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public screenWidthChanged(): BehaviorSubject<boolean> {
    return this.isMobileSubject;
  }

  public realScreenWidthChanged(): BehaviorSubject<number> {
    return this.screenWidthSubject;
  }

  public setScreenWidth(width: number) {
    this.screenWidthSubject.next(width);
    const isMobile = width < 1200 ? true : false;
    this.isMobileSubject.next(isMobile);
  }
}
