import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getMediaTypeFor } from '@core/utils';
import { TranslateService } from '@ngx-translate/core';
import { FileReference, RemoteResource } from '@shared/model';
import { AddManifestVersionDialog } from '../add-manifest-version-dialog/add-manifest-version.dialog';

@Component({
  selector: 'add-documentation-dialog',
  templateUrl: './add-documentation.dialog.html',
  styleUrls: ['./add-documentation.dialog.scss'],
})
export class AddDocumentationDialog implements OnInit {
  public isLoading: boolean;
  public documentation: RemoteResource | undefined;

  public errorUploadMessage: string;
  public showProgressMessage: boolean = false;
  public errorMessageSelectedFile: string;
  public convertedFileSize: string;

  private readonly maxFileSize: number = 1073741824;
  private readonly allowedMediaTypes: string[] = [
    'application/zip',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'application/x-rar',
    'application/x-rar-compressed',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddManifestVersionDialog>,
    private translate: TranslateService,
  ) {}

  ngOnInit() {}

  public async selectDocumentation(files: File[]) {
    this.isLoading = true;
    this.errorMessageSelectedFile = '';
    const file: File = files[0];
    if (file) {
      const fileMeta: FileReference = await this.getMetaDatafor(file);
      if (this.isFileValid(fileMeta)) {
        this.documentation = {
          name: fileMeta.name,
          mediaType: fileMeta.mediaType,
          size: fileMeta.size,
          uuid: '',
          url: '',
          file: file,
        };
      }
    } else {
      this.setWrongMediaTypeErrorMessage();
    }
    this.isLoading = false;
  }

  public addDocumentation() {
    this.dialogRef.close(this.documentation);
  }

  public cancel() {
    this.dialogRef.close();
  }

  private isFileValid(fileReference: FileReference): boolean {
    if (!this.isMediaTypeValid(fileReference.mediaType)) {
      this.setWrongMediaTypeErrorMessage();
      return false;
    } else if (!this.isFileSizeValid(fileReference.size)) {
      this.errorMessageSelectedFile = this.translate.instant('dialog.docu.add.too-big');
      return false;
    }
    return true;
  }

  private isMediaTypeValid(mediaType: string): boolean {
    return this.allowedMediaTypes.includes(mediaType);
  }

  private isFileSizeValid(size: number): boolean {
    return size < this.maxFileSize;
  }

  private setWrongMediaTypeErrorMessage() {
    this.errorMessageSelectedFile = this.translate.instant('dialog.docu.add.invalid');
  }

  private async getMetaDatafor(file: File): Promise<FileReference> {
    return {
      name: file.name,
      size: file.size,
      mediaType: await getMediaTypeFor(file),
    };
  }
}
