import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as semver from '@core/utils';
import { findByXpath, readFile } from '@core/utils';
import { RemoteResource, UnpublishedManifestVersion } from '@shared/model';

@Component({
  selector: 'add-manifest-version-dialog',
  templateUrl: './add-manifest-version.dialog.html',
  styleUrls: ['./add-manifest-version.dialog.scss'],
})
export class AddManifestVersionDialog implements OnInit {
  public manifestFile: File;
  public firmwareVersion: string;
  public appEngineVersion: string;
  public validFirmwareVersion: boolean;
  public validAppEngineVersion: boolean;
  public isProcessingManifest: boolean;
  public documentation: RemoteResource | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddManifestVersionDialog>) {}

  ngOnInit() {
    this.manifestFile = this.data.file;
    this.loadManifestContent(this.manifestFile);
  }

  public addManifestVersion() {
    const manifestVersion: UnpublishedManifestVersion = {
      firmwareVersion: this.firmwareVersion,
      appEngineVersion: this.appEngineVersion,
      file: this.manifestFile,
    };
    this.dialogRef.close(manifestVersion);
  }

  public cancel() {
    this.dialogRef.close();
  }

  public validateFirmware(version: string) {
    this.validFirmwareVersion = this.validate(version);
  }

  public validateAppEngine(version: string) {
    this.validAppEngineVersion = this.validate(version);
  }

  public validate(version: string): boolean {
    if (semver.isCompatible(version)) {
      return true;
    }
    return false;
  }

  private async loadManifestContent(manifest: File) {
    this.isProcessingManifest = true;
    const content: string = await readFile(manifest);
    const firmwareVersionAdded = findByXpath(content, '/manifest/application/meta[@key="version"][1]/text()');
    this.firmwareVersion = this.loadNewManifest(firmwareVersionAdded, this.firmwareVersion);
    this.validateFirmware(this.firmwareVersion);

    const appEngineVersionAdded = findByXpath(content, '/manifest/application/meta[@key="AppEngineVersion"][1]/text()');
    this.appEngineVersion = this.loadNewManifest(appEngineVersionAdded, this.appEngineVersion);
    this.validateAppEngine(this.appEngineVersion);
    this.isProcessingManifest = false;
  }

  /**
   * Returns the correct manifest version. If there's a mismatch between an already added version and a new one, it will return an empty string.
   * @param versionNew New added version
   * @param version version that is already added or not initialized
   */
  private loadNewManifest(versionNew: string | null | undefined, version: string): string {
    if (versionNew && (version === undefined || version === versionNew)) {
      return versionNew;
    } else {
      return '';
    }
  }
}
