import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-manifest-version-dialog',
  templateUrl: './delete-manifest-version.dialog.html',
  styleUrls: ['./delete-manifest-version.dialog.scss'],
})
export class RemoveManifestVersionDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<RemoveManifestVersionDialog>) {}

  public deleteVersion() {
    this.dialogRef.close(['delete']);
  }

  public cancel() {
    this.dialogRef.close();
  }
}
