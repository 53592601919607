import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponsiveService } from '@core/services';
import { ToastType } from '@shared/enums';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './app-toast-notification.component.html',
  styleUrls: ['./app-toast-notification.component.scss'],
})
export class AppToastNotificationComponent implements OnInit {
  @Input()
  public id: number;

  @Input()
  public title: string;

  @Input()
  public type: ToastType;

  @Input()
  public timeout?: number = 12000;

  @Input()
  public labelButton?: string;

  @Input()
  public message: string;

  @Output()
  close = new EventEmitter<number>();

  @Output()
  actionButton = new EventEmitter<number>();

  public isVisible: boolean = true;
  public screenWidth: number;
  public iconPath: string;

  private timer: any;

  constructor(private responsiveService: ResponsiveService) {
    this.responsiveService.realScreenWidthChanged().subscribe((width) => (this.screenWidth = width));
  }

  ngOnInit() {
    // https://stackoverflow.com/a/3969760
    const pausableTimer = function (callback: any, delay: any) {
      let timerId: number;

      this.pause = function () {
        window.clearTimeout(timerId);
      };

      this.resume = function () {
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, delay);
      };
      this.resume();
    };

    // If timeout === -1 show until user explicitely closes.
    // Toasts without a button wont close automatically.
    const that = this;
    if (this.timeout !== -1) {
      this.timer = new (pausableTimer as any)(function () {
        that.closeToast();
      }, this.timeout);
    }

    this.setIconPathFor(this.type);
  }

  public setIconPathFor(type: ToastType) {
    if (ToastType.ERROR === type) {
      this.iconPath = 'alert/error';
    } else if (ToastType.WARNING === type) {
      this.iconPath = 'action/report_problem';
    } else if (ToastType.SUCCESS === type) {
      this.iconPath = 'action/check_circle';
    } else {
      // Default: ToastType.INFO
      this.iconPath = 'action/info';
    }
  }

  public closeToast() {
    this.timer.pause();
    this.close.emit(this.id);
  }

  public actionButtonClicked() {
    this.timer.pause();
    this.actionButton.emit(this.id);
  }
}
