<h2 mat-dialog-title>{{ 'dialog.device.title' | translate }}</h2>

<mat-dialog-content class="content">
  <davinci-callout *ngIf="isAlreadyAddedDevice" type="error">{{ 'dialog.device.duplicate' | translate }}</davinci-callout>
  <davinci-enhanced-drop-down
    class="search-device"
    editable
    placeholder="{{ 'dialog.device.search' | translate }}"
    [contentRenderer]="customContentRenderer"
    [valueRenderer]="customValueRenderer"
    (input)="searchForDevices($event)"
    (change)="selectDevice($event)"
  >
  </davinci-enhanced-drop-down>

  <div class="divider"></div>

  <div class="device-data">
    <davinci-text-field
      readonly
      class="input readyonly"
      label="{{ 'dialog.device.partnumber' | translate }}"
      [(ngModel)]="partNumber"
      [error]="selectedDevice && !selectedDevice.partNumber"
    ></davinci-text-field>
    <davinci-status-text *ngIf="selectedDevice && !selectedDevice.partNumber" error>{{
      'dialog.device.missing-partnumber' | translate
    }}</davinci-status-text>

    <davinci-text-field
      readonly
      class="input readyonly"
      label="{{ 'dialog.device.devicetype' | translate }}"
      [(ngModel)]="deviceType"
      [error]="selectedDevice && !selectedDevice.deviceType"
    ></davinci-text-field>
    <davinci-status-text *ngIf="selectedDevice && !selectedDevice.deviceType" error>{{
      'dialog.device.missing-devicetype' | translate
    }}</davinci-status-text>

    <davinci-text-field
      readonly
      class="input readyonly"
      label="{{ 'dialog.device.productfamily' | translate }}"
      [(ngModel)]="productFamily"
      [error]="selectedDevice && !selectedDevice.productFamily"
    ></davinci-text-field>
    <davinci-status-text *ngIf="selectedDevice && !selectedDevice.productFamily" error>{{
      'dialog.device.missing-productfamily' | translate
    }}</davinci-status-text>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-area">
  <davinci-button class="button" type="bare-alt" (click)="cancel()">{{ 'dialog.device.cancel' | translate }}</davinci-button>
  <davinci-button
    type="primary"
    class="confirm button"
    (click)="addDevice()"
    [disabled]="
      !(selectedDevice && selectedDevice.deviceType && selectedDevice.partNumber && selectedDevice.productFamily) ||
      isAlreadyAddedDevice
    "
  >
    {{ 'dialog.device.add' | translate }}
  </davinci-button>
</mat-dialog-actions>
