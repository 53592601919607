import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Feedback } from '@shared/model';
import { environment } from 'environments/environment';

@Injectable()
export class FeedbackService {
  private baseUrl: string;

  public constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apppoolUrl;
  }

  public sendFeedback(feedback: Feedback): Promise<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/feedback`, feedback).toPromise();
  }
}
