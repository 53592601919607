import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toast } from '@shared/model';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject: Subject<Toast> = new Subject<Toast>();

  public toastsChanged(): Subject<Toast> {
    return this.toastSubject;
  }

  public create(toast: Toast) {
    this.toastSubject.next(toast);
  }
}
