import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// make sure JSNLog does not install its default error handlers before the logging has been initialized
window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
  const cons = console;
  cons.error('Uncaught Exception: %s at %d in %s', errorMsg, lineNumber, url);
  return true;
};

window.onunhandledrejection = (event) => {
  const cons = console;
  cons.error('Unhandled Rejection: %o', event);
  return true;
};

window.addEventListener('WebComponentsReady', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
