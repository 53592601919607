import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUiService, Logger, ManifestService, ToastService } from '@core/services';
import { HttpErrorTranslation } from '@core/translation/http-error-translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@shared/enums';
import {
  Device,
  ManifestDetail,
  ManifestVersionDetail,
  PresignedUrl,
  RemoteResource,
  UnpublishedManifestVersion,
  UploadResource,
} from '@shared/model';

@Component({
  selector: 'manifest-publish-button',
  templateUrl: './manifest-publish-button.component.html',
  styleUrls: ['./manifest-publish-button.component.scss'],
})
export class ManifestPublishComponent {
  @Input() manifest: ManifestDetail;
  @Input() manifestGhost: ManifestDetail;
  @Output() publishedFinished: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private manifestService: ManifestService,
    private logger: Logger,
    private translate: TranslateService,
    private toastService: ToastService,
    private uiBlocker: BlockUiService,
    private errorTranslationService: HttpErrorTranslation,
  ) {}

  public async publish() {
    this.uiBlocker.enable('manifest-detail.publish');

    try {
      const isExistingManifest: boolean = !!this.manifest.uuid;
      if (!isExistingManifest) {
        const manifestCreated = await this.manifestService.createManifest(this.manifest);
        this.manifest.uuid = <string>manifestCreated.uuid;
      } else {
        await this.manifestService.updateManifest(this.manifest);
      }

      this.manifest.devices = await this.updateDevicesFor(this.manifest, this.manifestGhost.devices, this.manifest.devices);
      this.manifest.versions = await this.updateManifestVersions(this.manifestGhost.versions, this.manifest.versions);

      await this.router.navigate(['manifest/', this.manifest.uuid]);
      this.publishedFinished.emit();
    } catch (error) {
      console.error(error);
      const errorMessage: string | undefined = this.errorTranslationService.tryTranslateError(error)?.errorDescription;
      this.toastService.create({
        title: this.translate.instant('manifest-detail.general.error'),
        message: errorMessage ? errorMessage : 'Unknown',
        type: ToastType.ERROR,
      });
    } finally {
      this.uiBlocker.disable();
    }
  }

  private async updateDevicesFor(manifest: ManifestDetail, oldDevices: Device[], updatedDevices: Device[]): Promise<Device[]> {
    const deletionDevices = this.getAssetsToRemove(oldDevices, updatedDevices);
    await Promise.all(deletionDevices.map(async (device) => await this.removeDevice(manifest, device)));

    const creationDevices = updatedDevices.filter((newDevice) => !newDevice.uuid);
    await Promise.all(creationDevices.map(async (device) => await this.addDeviceTypeTo(manifest, device)));
    return updatedDevices;
  }

  private async removeDevice(manifest: ManifestDetail, device: Device): Promise<void> {
    try {
      await this.manifestService.removeDeviceType(manifest.uuid, <string>device.uuid);
    } catch (err) {
      this.logger.error('DELETE_DEVICE_TYPE', { device }, err);
      throw err;
    }
  }

  private async addDeviceTypeTo(manifest: ManifestDetail, device: Device): Promise<void> {
    try {
      const res = await this.manifestService.addDeviceTypeTo(manifest, device);
      device.uuid = res.uuid;
    } catch (err) {
      const errorMessage: string | undefined = this.errorTranslationService.tryTranslateError(err)?.errorDescription;
      this.toastService.create({
        title: this.translate.instant('manifest-detail.device.error'),
        message: errorMessage ? errorMessage : 'Unknown',
        type: ToastType.ERROR,
      });
      this.logger.error(
        'SUBMIT_NEW_DEVICETYPE_FAILED',
        {
          deviceType: device.deviceType,
          partNumber: device.partNumber,
          selectedDeviceType: device,
        },
        err,
      );
      throw err;
    }
  }

  private async updateManifestVersions(
    oldManifestVersions: ManifestVersionDetail[],
    updatedManifestVersions: ManifestVersionDetail[],
  ): Promise<ManifestVersionDetail[]> {
    const deletionVersions = this.getAssetsToRemove(oldManifestVersions, updatedManifestVersions);
    await Promise.all(deletionVersions.map(async (version) => await this.deleteManifestVersion(version)));

    const createdVersions: ManifestVersionDetail[] = await Promise.all(
      updatedManifestVersions
        .filter((manifest) => !manifest.uuid)
        .map(async (version) => {
          const createdVersion = await this.createManifestVersionFor(this.manifest, version);
          if (this.isNotYetCreated(version.documentationResource)) {
            return await this.addDocumentationTo(createdVersion.uuid, <RemoteResource>version.documentationResource);
          }
          return createdVersion;
        }),
    );

    const updatesVersionWithAddedDocu: ManifestVersionDetail[] = await Promise.all(
      updatedManifestVersions
        .filter((manifest) => manifest.uuid)
        .map(async (version) => {
          if (this.isNotYetCreated(version.documentationResource)) {
            return await this.addDocumentationTo(version.uuid, <RemoteResource>version.documentationResource);
          }
          return version;
        }),
    );

    updatesVersionWithAddedDocu.push(...createdVersions);
    return updatesVersionWithAddedDocu;
  }

  private async deleteManifestVersion(version: ManifestVersionDetail): Promise<void> {
    try {
      await this.manifestService.removeManifestVersion(<string>version.uuid);
    } catch (err) {
      this.logger.error('DELETE_MANIFEST', { manifest: version }, err);
      throw err;
    }
  }

  private async createManifestVersionFor(
    manifest: ManifestDetail,
    version: UnpublishedManifestVersion,
  ): Promise<ManifestVersionDetail> {
    const manifestBinaryMeta: UploadResource = await this.uploadFile(<File>version.file);
    return await this.manifestService.confirmManifestVersionUpload(manifest.uuid, version, manifestBinaryMeta);
  }

  private getAssetsToRemove(oldAssets: any[], updatedAssets: any[]): any[] {
    const assetUuidsToKeep: string[] = updatedAssets.map((asset) => <string>asset.uuid);
    const assetsToRemove: any[] = oldAssets.filter((asset) => !assetUuidsToKeep.includes(<string>asset.uuid));
    return assetsToRemove;
  }

  private async addDocumentationTo(versionUuid: string, documentation: RemoteResource): Promise<ManifestVersionDetail> {
    if (!documentation) {
      throw Error(`Documentation not defined for version with uuid ${versionUuid}`);
    }
    const documentationBinaryMeta: UploadResource = await this.uploadFile(<File>documentation.file);
    return await this.manifestService.confirmDocumentationUploadFor(versionUuid, documentationBinaryMeta);
  }

  private async uploadFile(file: File): Promise<UploadResource> {
    const presignedUploadUrl: PresignedUrl = await this.getPresignedUrl();
    await this.manifestService.uploadFileBinary(file, presignedUploadUrl);
    const documentationBinaryMeta: UploadResource = {
      name: file.name,
      size: file.size,
      mediaType: file.type,
      url: presignedUploadUrl.resourceAddress,
    };
    return documentationBinaryMeta;
  }

  private async getPresignedUrl(): Promise<PresignedUrl> {
    try {
      return await this.manifestService.createPresignedUploadUrl();
    } catch (err) {
      this.logger.error('GET_PRESIGNED_URL', {}, err);
      throw err;
    }
  }

  private isNotYetCreated(documentation?: RemoteResource): boolean {
    return !!(documentation && !documentation.uuid);
  }
}
