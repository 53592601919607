import { animation, animate, style } from '@angular/animations';

export const flyUpDownAnimation = animation([
  style({
    transform: 'translateY(0%)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '64px',
    opacity: 0,
  }),
  animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: 'translateY(300%)', opacity: 1 })),
]);
