import { Component, OnInit } from '@angular/core';
import { BlockUiService } from '@core/services';

@Component({
  selector: 'app-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
})
export class BlockUiComponent implements OnInit {
  public block = false;
  public action: string;
  public status = 'pending';

  constructor(private blockingService: BlockUiService) {}

  ngOnInit(): void {
    this.blockingService.set(this);
  }

  public enable(action?: string) {
    if (action) {
      this.action = action;
    }
    this.status = 'pending';
    this.block = true;
  }

  public done() {
    this.action = 'action.done';
    this.status = 'success';
    this.block = true;
  }

  public fail() {
    this.action = 'action.fail';
    this.status = 'error';
    this.block = true;
  }

  public disable() {
    this.action = '';
    this.block = false;
  }
}
