import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegrationLayerService, Logger, ManifestService } from '@core/services';
import { Device, ProductListItem, UnpublishedDevice } from '@shared/model';
import { DropDown, TemplateResult, html } from '@sick-davinci/basic-elements';
import './EnhancedDropDown';

@Component({
  selector: 'add-device-dialog',
  templateUrl: './add-device.dialog.html',
  styleUrls: ['./add-device.dialog.scss'],
})
export class AddDeviceDialog implements OnInit, AfterViewInit {
  public partNumber: string = '';
  public deviceType: string = '';
  public productFamily: string = '';

  public title: string;
  public selectedDevice: UnpublishedDevice | undefined;
  public productResultList: ProductListItem[] = [];
  public productResultListDropDown: any[] = [];
  public isAlreadyAddedDevice: boolean;

  private searchTimeout: NodeJS.Timeout;
  private alreadyAddedDevices: Device[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddDeviceDialog>,
    private changeDetectorRef: ChangeDetectorRef,
    private integrationlayerService: IntegrationLayerService,
    private manifestServcie: ManifestService,
    private logger: Logger,
  ) {}

  ngOnInit() {
    setTimeout(async () => {
      this.alreadyAddedDevices = await this.getAllAvailableDevices();
    }, 100);
    this.setAttributesForDevice(this.data.device);
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  public customContentRenderer(value: any, label: string): TemplateResult {
    let dropdownItem: TemplateResult;
    if (value.ProductFamily) {
      dropdownItem = html`<div style="padding: 8px 0px; line-height: 24px;">
        <div>${value.Name}</div>
        <div>${value.ProductFamily} (${value.PartNumber})</div>
      </div>`;
    } else {
      dropdownItem = html`<div style="padding: 8px 0px; line-height: 24px;">${value.Name} (${value.PartNumber})</div>`;
    }
    return dropdownItem;
  }

  public customValueRenderer(value: ProductListItem): string {
    return value && value.Name ? value.Name : '';
  }

  public searchForDevices(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.resetTimeout();
    this.resetDeviceSelection();

    const searchTerm: string = event.detail;
    if (searchTerm.length < 3) {
      return;
    }
    this.productResultListDropDown = [];
    this.searchTimeout = setTimeout(async () => {
      const dropdown: DropDown = event.target;
      dropdown.loading = true;

      try {
        this.productResultList = await this.integrationlayerService.getProducts(searchTerm);

        for (const productResult of this.productResultList) {
          this.productResultListDropDown.push({
            value: productResult,
            label: productResult.Name + ' (' + productResult.PartNumber + ')',
          });
        }
        dropdown.data = this.productResultListDropDown;
        dropdown.expanded = true;
      } catch (err) {
        this.resetDeviceSelection();
        dropdown.expanded = false;
        this.logger.error(
          'DEVICE_PRODUCT_SEARCH',
          {
            searchPattern: searchTerm,
          },
          err,
        );
      } finally {
        this.resetTimeout();
        dropdown.loading = false;
      }
    }, 250);
  }

  public async selectDevice(event: any) {
    const selectedDevice = event.detail;
    if (selectedDevice) {
      this.selectedDevice = {
        deviceType: selectedDevice.Name,
        partNumber: selectedDevice.PartNumber,
        productFamily: selectedDevice.ProductFamily,
      };
      this.setAttributesForDevice(this.selectedDevice);
      this.isAlreadyAddedDevice = this.isDeviceAlreadyAddedToAnyManifest(this.selectedDevice, this.alreadyAddedDevices);
    }
  }

  public addDevice() {
    this.dialogRef.close(this.selectedDevice);
  }

  public cancel() {
    this.dialogRef.close();
  }

  private async getAllAvailableDevices(): Promise<Device[]> {
    const productFamilyWithDevices = await this.manifestServcie.getDeviceTypesGroupedByProductFamilies();
    return productFamilyWithDevices.reduce((a, c) => [...a, ...c.devices.map((device) => device)], []);
  }

  private isDeviceAlreadyAddedToAnyManifest(selectedDevice: UnpublishedDevice, alreadyAddedDevices: Device[]): boolean {
    return !!alreadyAddedDevices.find((addedDevice) => addedDevice.partNumber === selectedDevice?.partNumber);
  }

  private setAttributesForDevice(device: UnpublishedDevice) {
    if (device) {
      this.partNumber = device.partNumber;
      this.deviceType = device.deviceType;
      this.productFamily = device.productFamily;
    }
  }

  private resetDeviceSelection() {
    this.productResultList = [];
    this.selectedDevice = undefined;
    this.partNumber = '';
    this.deviceType = '';
    this.productFamily = '';
    this.isAlreadyAddedDevice = false;
  }

  private resetTimeout() {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }
  }
}
