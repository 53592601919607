import { Injectable } from '@angular/core';
import { Environment } from '@shared/model';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Logger } from '../logger.service';
import { User } from './user';

@Injectable()
export class AuthService {
  private hasBearerToken = false;
  private user: User | undefined;

  constructor(private oAuthService: OAuthService, private logger: Logger) {}

  async initialize(environment: Environment): Promise<void> {
    await this.configureOidc(environment);
  }

  public currentUser(): User | undefined {
    return this.user;
  }

  public logOut(): void {
    this.logger.logAction('Logging out user', this.user);
    this.oAuthService.logOut();
  }

  public hasValidBearerToken(): boolean {
    return this.hasBearerToken;
  }

  /**
   * here you can add whatever kind of check you may
   * will be called immediately after login
   */
  public additionalCheck() {
    return true;
  }

  /**
   * Setup OIDC and try to login
   */
  private async configureOidc(environment: Environment) {
    this.logger.debug('Configure Oidc');
    const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const oidcDefaults: AuthConfig = {
      redirectUri,
      responseType: 'code',
      timeoutFactor: 0.5,
    };
    const oidcConfig = { ...oidcDefaults, ...environment.oidc };
    this.oAuthService.configure(oidcConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    this.setupAutomaticLogoutInCaseOfTokenExpiry();

    if (!this.oAuthService.hasValidIdToken()) {
      const result = await this.oAuthService.loadDiscoveryDocumentAndLogin();
      if (result) {
        this.hasBearerToken = true;
        this.user = await this.initializeUser();
        return this.additionalCheck();
      } else {
        this.logger.error('Failed to get bearer', { result });
        this.hasBearerToken = false;
      }
    } else {
      const result = await this.oAuthService.loadDiscoveryDocument();
      if (result) {
        this.hasBearerToken = true;
        this.user = await this.initializeUser();
        return this.additionalCheck();
      }
    }
  }

  private async initializeUser(): Promise<User> {
    const claims = this.oAuthService.getIdentityClaims();
    const user = User.initializeUserFor(claims);
    this.logger.debug('User is logged in:' + user);
    return user;
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry() {
    if (!this.oAuthService.events) {
      return;
    }

    this.oAuthService.events.subscribe((x: any) => {
      const errorType = x.type;
      if (errorType === 'token_refresh_error') {
        if (x.reason && x.reason.error && x.reason.error.error === 'invalid_grant') {
          this.oAuthService.logOut();
        } else {
          this.logger.warn({ 'Client lost internet connectivity': x });
          this.suspendSilentTokenRefresh();
        }
      }
    });
  }

  private suspendSilentTokenRefresh() {
    this.oAuthService.stopAutomaticRefresh();
    const refreshTimeout = setTimeout(() => {
      this.oAuthService
        .refreshToken()
        .then(() => {
          this.oAuthService.setupAutomaticSilentRefresh();
          clearTimeout(refreshTimeout);
        })
        .catch(() => {
          clearTimeout(refreshTimeout);
        });
    }, 5000);
  }
}
