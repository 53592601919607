import { UserRole } from '@shared/enums';

export class User {
  private roles: string[] = [];

  private constructor(
    public firstName: string,
    public lastName: string,
    public readonly mailAddress: string,
    public readonly companyName: string,
    public readonly foreignUserId: string,
  ) {}

  public static initializeUserFor(accountObject: any): User {
    const user = new User(
      accountObject.given_name,
      accountObject.family_name,
      accountObject.email,
      accountObject.companyName,
      accountObject.userId,
    );
    user.addRoles(accountObject.roles);
    return user;
  }

  public addRoles(roles: string[]) {
    if (!roles) {
      return;
    }
    this.roles = this.roles.concat(roles);
  }

  public hasRole(role: string): boolean {
    return this.roles.findIndex((r) => r === role) !== -1;
  }

  public isClubMember(): boolean {
    return this.hasRole(UserRole.APPSPACE_DEV_CLUB_MEMBER);
  }

  public displayName(): string {
    let displayName = this.firstName + ' ' + this.lastName;
    if (this.companyName) {
      displayName += ', ' + this.companyName;
    }

    return displayName;
  }
}
