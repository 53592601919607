import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HttpBusyService {
  private visible = false;
  private subject = new BehaviorSubject<boolean>(false);

  public requestStarts() {
    this.setVisible(true);
  }

  public requestEnds() {
    this.setVisible(false);
  }

  public observable() {
    return this.subject.asObservable();
  }

  private setVisible(b: boolean) {
    if (b !== this.visible) {
      this.visible = b;
      this.subject.next(this.visible);
    }
  }
}
