<h2 mat-dialog-title>{{ 'dialog.manifest-version.add.title' | translate }}</h2>

<mat-dialog-content class="content">
  <div class="manifest">
    <div class="manifest-meta">
      <h5 class="manifest-name">{{ manifestFile.name }}</h5>
    </div>
    <div class="divider"></div>
    <div class="manifest-content">
      <div *ngIf="!isProcessingManifest; else isLoading" class="versions">
        <div class="version">
          <davinci-text-field
            label="{{ 'dialog.manifest-version.add.firmware' | translate }}"
            [(ngModel)]="firmwareVersion"
            [error]="!validFirmwareVersion"
            (input)="validateFirmware($event.target.value)"
          ></davinci-text-field>
          <davinci-status-text *ngIf="!validFirmwareVersion" error>{{
            'dialog.manifest-version.add.invalid' | translate
          }}</davinci-status-text>
        </div>
        <div class="version">
          <davinci-text-field
            label="{{ 'dialog.manifest-version.add.appengine' | translate }}"
            disabled
            [(ngModel)]="appEngineVersion"
            [error]="!validAppEngineVersion"
            (input)="validateAppEngine($event.target.value)"
          ></davinci-text-field>
          <davinci-status-text *ngIf="!validAppEngineVersion" error>{{
            'dialog.manifest-version.add.invalid' | translate
          }}</davinci-status-text>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-area">
  <davinci-button class="button" type="bare-alt" (click)="cancel()">{{
    'dialog.manifest-version.add.cancel' | translate
  }}</davinci-button>
  <davinci-button
    type="primary"
    class="confirm button"
    (click)="addManifestVersion()"
    [disabled]="!validFirmwareVersion || !validAppEngineVersion"
  >
    {{ 'dialog.manifest-version.add.add' | translate }}
  </davinci-button>
</mat-dialog-actions>

<ng-template #isLoading>
  <davinci-spinner class="loading"></davinci-spinner>
</ng-template>
