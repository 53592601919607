import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddManifestVersionDialog } from '@shared/dialogs';
import { ManifestDetail, ManifestVersionDetail } from '@shared/model';

@Component({
  selector: 'manifests',
  templateUrl: './manifests.component.html',
  styleUrls: ['./manifests.component.scss'],
})
export class ManifestsComponent {
  @Input() manifest: ManifestDetail;
  @Input() editMode: boolean;

  public errorMessageSelectedFile: string;
  public dialogRefAddManifest: MatDialogRef<AddManifestVersionDialog>;

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  public async deleteManifestVersion(versionToDelete: ManifestVersionDetail) {
    this.manifest.versions = this.manifest.versions.filter((version) => version.uuid !== versionToDelete.uuid);
  }

  public async selectManifestVersion(files: File[]) {
    this.errorMessageSelectedFile = '';
    const selectedFile = files[0];
    if (selectedFile) {
      this.openAddManifestVersionDialogFor(files[0]);
    } else {
      this.errorMessageSelectedFile = this.translate.instant('manifest.dialogs.error.invalidFormatManifest');
    }
  }

  private openAddManifestVersionDialogFor(file: File) {
    this.dialogRefAddManifest = this.dialog.open(AddManifestVersionDialog, {
      data: { file: file },
    });
    this.dialogRefAddManifest.afterClosed().subscribe(async (result) => {
      if (result) {
        this.manifest.versions.push(result);
      }
    });
  }
}
