import { Component } from '@angular/core';
import { ManifestDetail, ManifestWizardStep } from '@shared/model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'create-manifest-wizard',
  templateUrl: './create-manifest-wizard.component.html',
  styleUrls: ['./create-manifest-wizard.component.scss'],
})
export class CreateManifestWizardComponent {
  public editMode$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public manifest: ManifestDetail = {
    name: '',
    uuid: '',
    publisher: '',
    devices: [],
    versions: [],
  };
  public manifestGhost: ManifestDetail = JSON.parse(JSON.stringify(this.manifest));

  public wizardSteps: ManifestWizardStep[] = [
    {
      step: 0,
      messageKey: 'manifest-create.overview',
    },
    {
      step: 1,
      messageKey: 'manifest-create.devices',
    },
    {
      step: 2,
      messageKey: 'manifest-create.versions',
    },
  ];
  public selectedStep: ManifestWizardStep = this.wizardSteps[0];

  constructor() {}

  public async jumpTo(step: number) {
    const foundWizardStep = this.wizardSteps.find((wizardStep) => wizardStep.step === step);
    if (!foundWizardStep) {
      throw Error('Not found manifest wizard step');
    }
    this.selectedStep = foundWizardStep;
  }
}
