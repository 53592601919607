import { Component, Input } from '@angular/core';
import { ManifestWizardStep } from '@shared/model';

@Component({
  selector: 'manifest-wizard-control',
  styleUrls: ['./manifest-wizard-control.component.scss'],
  templateUrl: './manifest-wizard-control.component.html',
})
export class ManifestWizardControlComponent {
  @Input() wizardSteps: ManifestWizardStep[] = [];
  @Input() selectedStep: ManifestWizardStep;

  constructor() {
    // this.wizardSteps = this.wizardService.getAllSteps();
    // this.wizardService.getSelectedStep().subscribe((step) => {
    //   this.selectedStep = step;
    // });
  }

  public jumpTo(step: number) {
    // this.wizardService.jumpTo(this.selectedStep.step, step);
  }
}
