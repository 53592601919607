import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpBusyService } from '@core/services';
import { tap, finalize } from 'rxjs/operators';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {
  private requests = 0;

  constructor(
    @Inject(forwardRef(() => HttpBusyService))
    private httpBusy: HttpBusyService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Sent) {
          this.trackRequest();
          return;
        }
      }),
      finalize(() => {
        this.untrackRequest();
      }),
    );
  }

  private trackRequest() {
    this.requests++;
    if (this.requests === 1) {
      this.httpBusy.requestStarts();
    }
  }

  private untrackRequest() {
    this.requests--;
    if (this.requests === 0) {
      this.httpBusy.requestEnds();
    }
  }
}
