<app-toast-notification
  @flyInOut
  class="toast"
  *ngFor="let toast of toasts"
  [id]="toast.id"
  [type]="toast.type"
  [message]="toast.message"
  [title]="toast.title"
  [attr.timeout]="toast.timeout"
  [labelButton]="toast.labelButton"
  (close)="deleteToast($event)"
  (actionButton)="clickedActionButton($event)"
>
</app-toast-notification>
