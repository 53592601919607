import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import {
  Device,
  ProductFamilyWithDevices,
  UploadResource,
  PresignedUrl as UploadEndpoint,
  Manifest,
  ManifestDetail,
  ManifestVersionDetail,
  UnpublishedManifestVersion,
} from '@shared/model';
import { environment } from 'environments/environment';
import { lastValueFrom, map, Observable } from 'rxjs';

@Injectable()
export class ManifestService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.deviceManifestUrl;
  }

  // Manifest
  public createManifest(manifest: Manifest): Promise<Manifest> {
    return lastValueFrom(this.httpClient.post<Manifest>(`${this.baseUrl}/manifest`, manifest));
  }

  public updateManifest(manifest: Manifest): Promise<Manifest> {
    return lastValueFrom(this.httpClient.put<Manifest>(`${this.baseUrl}/manifest/${manifest.uuid}`, manifest));
  }

  public getAllManifests(): Observable<Manifest[]> {
    return this.httpClient.get<[]>(`${this.baseUrl}/manifests`);
  }

  public getManifestDetails(manifestUuid: string): Promise<ManifestDetail> {
    return lastValueFrom(this.httpClient.get<ManifestDetail>(`${this.baseUrl}/manifest/${manifestUuid}`));
  }

  public deleteManifest(manifestUuid: string): Promise<{}> {
    return lastValueFrom(this.httpClient.delete(`${this.baseUrl}/manifest/${manifestUuid}`));
  }

  // Manifest Version
  public confirmManifestVersionUpload(
    manifestUuid: string,
    manifestVersion: UnpublishedManifestVersion,
    manifestResource: UploadResource,
  ): Promise<ManifestVersionDetail> {
    const firmwareVersion = manifestVersion.firmwareVersion;
    const appEngineVersion = manifestVersion.appEngineVersion;
    return lastValueFrom(
      this.httpClient.post<ManifestVersionDetail>(`${this.baseUrl}/manifest/${manifestUuid}/version`, {
        firmwareVersion,
        appEngineVersion,
        manifestResource,
      }),
    );
  }

  public removeManifestVersion(versionUuid: string): Promise<{}> {
    return lastValueFrom(this.httpClient.delete(`${this.baseUrl}/manifest/version/${versionUuid}`));
  }

  public confirmDocumentationUploadFor(
    versionUuid: string,
    documentationResource: UploadResource,
  ): Promise<ManifestVersionDetail> {
    return lastValueFrom(
      this.httpClient.post<any>(`${this.baseUrl}/manifest/version/${versionUuid}/documentation`, documentationResource),
    );
  }

  public async downloadManifest(signedDownloadUrl: string): Promise<ArrayBuffer | null> {
    const request = new HttpRequest('GET', signedDownloadUrl, undefined, {
      responseType: 'arraybuffer',
      withCredentials: false,
    });
    const res = await lastValueFrom(
      this.httpClient.request<ArrayBuffer>(request).pipe(map((response: HttpResponse<ArrayBuffer>) => response.body)),
    );

    return res;
  }

  public getDownloadUrl(fileRefUuid: string): Promise<{ presignedResourceUrl: string }> {
    return lastValueFrom(
      this.httpClient.get<{ presignedResourceUrl: string }>(
        `${this.baseUrl}/manifest/version/binary/${fileRefUuid}/download-url`,
      ),
    );
  }

  // Devices
  public getAllDeviceTypes(): Promise<ProductFamilyWithDevices[]> {
    return lastValueFrom(this.httpClient.get<ProductFamilyWithDevices[]>(`${this.baseUrl}/deviceTypes`));
  }

  public addDeviceTypeTo(manifest: Manifest, device: Device): Promise<{ uuid: string }> {
    return lastValueFrom(this.httpClient.post<{ uuid: string }>(`${this.baseUrl}/manifest/${manifest.uuid}/deviceType`, device));
  }

  public updateDeviceType(device: Device): Promise<void> {
    return lastValueFrom(this.httpClient.put<void>(`${this.baseUrl}/manifest/deviceType/${device.uuid}`, device));
  }

  public removeDeviceType(manifestUuid: string, deviceUuid: string): Promise<{}> {
    return lastValueFrom(this.httpClient.delete(`${this.baseUrl}/manifest/${manifestUuid}/deviceType/${deviceUuid}`));
  }

  public getDeviceTypesGroupedByProductFamilies(): Promise<ProductFamilyWithDevices[]> {
    return lastValueFrom(this.httpClient.get<ProductFamilyWithDevices[]>(`${this.baseUrl}/deviceTypes`));
  }

  // General
  public createPresignedUploadUrl(): Promise<UploadEndpoint> {
    return lastValueFrom(this.httpClient.get<UploadEndpoint>(`${this.baseUrl}/manifest/upload`));
  }

  public uploadFileBinary(file: File, presignedUploadUrl: UploadEndpoint): Promise<{}> {
    return lastValueFrom(this.httpClient.put(presignedUploadUrl.presignedUrl, file));
  }
}
