<davinci-callout type="info" *ngIf="!editMode && manifest?.versions.length == 0">{{
  'manifests.na' | translate
}}</davinci-callout>
<davinci-dnd-file-chooser
  *ngIf="editMode"
  class="file-chooser"
  labelDrop="{{ 'dialog.manifest-version.add.drop' | translate }}"
  labelBrowse="{{ 'dialog.manifest-version.add.browse' | translate }}"
  (change)="selectManifestVersion($event.detail)"
  accept=".xml"
>
</davinci-dnd-file-chooser>

<div *ngFor="let version of manifest.versions | sortByFirmwareVersion">
  <version-item [version]="version" [editMode]="editMode" (onDelete)="deleteManifestVersion(version)"></version-item>
</div>
