import { JL } from 'jsnlog';
import { inspect } from 'util';
import { environment } from 'environments/environment';
const uuid = require('uuid');

export class Logger {
  private static readonly CONSOLE_LOGGER = 'consoleAppender';
  private static readonly AJAX_LOGGER = 'ajaxAppender';

  public foreignUserId: string;

  private logger: JL.JSNLogLogger;
  private sessionId: string;
  private commitRef: string = 'N/A';
  private commitSha: string = 'N/A';

  constructor() {
    this.configureLogger();

    window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
      JL().fatalException(
        {
          msg: 'Uncaught Exception',
          errorMsg: JSON.stringify(errorMsg, ['message', 'arguments', 'type', 'name']),
          url: url,
          'line number': lineNumber,
          column: column,
        },
        errorObj,
      );
      return false;
    };

    // Deal with unhandled exceptions thrown in promises
    (window as any).onunhandledrejection = (event: any) => {
      JL().fatalException(
        {
          msg: 'Unhandled Rejection',
          errorMsg: event.reason ? event.reason.message : null,
        },
        event.reason,
      );
    };
  }

  public debug(payload: object | string): void {
    if (!payload) {
      throw new Error('No payload provided for this log');
    }

    this.logger.debug({
      message: payload,
    });
  }

  public info(payload: object, details?: string): void {
    if (!payload) {
      payload = {};
    }

    if (details) {
      payload = Object.assign(payload, { details });
    }

    this.logger.info({
      message: payload,
      forwardToKibana: true,
    });
  }

  public infoWithoutPayload(details: string) {
    this.info({}, details);
  }

  public warn(payload: object, details?: string): void {
    if (!payload) {
      payload = {};
    }

    if (details) {
      payload = Object.assign(payload, { details });
    }

    this.logger.warn({
      message: payload,
      forwardToKibana: true,
    });
  }

  public error(action: string, payload: object, err?: any, details?: string): void {
    if (!action) {
      throw new Error('No action property provided');
    }

    if (!payload) {
      payload = {};
    }

    if (err) {
      payload = Object.assign(payload, { err: inspect(err, { depth: null }) });
    }

    if (payload) {
      payload = Object.assign(payload, { details });
    }

    this.logger.error({
      action: action,
      message: payload,
      forwardToKibana: true,
    });
  }

  public logAction(action: string, payload: object = {}, details?: string): void {
    if (!action) {
      throw new Error('No action property provided');
    }

    if (!payload) {
      payload = {};
    }

    if (details) {
      payload = Object.assign(payload, { details });
    }

    this.logger.info({
      action: action,
      message: payload,
      forwardToKibana: true,
    });
  }

  private configureLogger(): any {
    this.sessionId = uuid.v4();

    const consoleAppender = JL.createConsoleAppender(Logger.CONSOLE_LOGGER);
    if (consoleAppender.setOptions) {
      consoleAppender?.setOptions({
        level: this.logLevelFromString(environment.logLevelConsole),
      });
    }

    const ajaxAppender = JL.createAjaxAppender(Logger.AJAX_LOGGER);
    if (ajaxAppender.setOptions) {
      ajaxAppender.setOptions({
        level: this.logLevelFromString(environment.logLevelConsole),
      });
    }

    JL.setOptions({
      defaultAjaxUrl: environment.apppoolUrl + '/' + 'jsnlog.logger',
      requestId: this.getNewGuidString(),
      defaultBeforeSend: this.beforeSend.bind(this),
    });

    const appenders = [];
    if (this.enableAjaxLogging()) {
      appenders.push(ajaxAppender);
    }
    if (this.enableConsoleLogging()) {
      appenders.push(consoleAppender);
    }

    this.logger = JL();
    this.logger.setOptions({
      appenders,
    });
  }

  private beforeSend(xhr: XMLHttpRequest, json?: any) {
    xhr.setRequestHeader('x-api-key', environment.apppoolApiKey);
    json.lg.forEach((e: any) => {
      const data = JSON.parse(e.m);
      e.m = {
        user: this.foreignUserId,
        sessionId: this.sessionId,
        data: data.message,
        commitRef: this.commitRef,
        commitSha: this.commitSha,
        action: data.action,
        forwardToKibana: data.forwardToKibana,
      };
    });
  }

  private logLevelFromString(logLevel: string): number {
    const level: string = logLevel.toLowerCase();
    if (level === 'off') {
      return JL.getOffLevel();
    }
    if (level === 'trace') {
      return JL.getTraceLevel();
    }
    if (level === 'debug') {
      return JL.getDebugLevel();
    }
    if (level === 'info') {
      return JL.getInfoLevel();
    }
    if (level === 'warn') {
      return JL.getWarnLevel();
    }
    if (level === 'error') {
      return JL.getErrorLevel();
    }
    return JL.getOffLevel();
  }

  // borrowed from https://stackoverflow.com/questions/37144672/guid-uuid-type-in-typescript
  private getNewGuidString(): string {
    // your favourite guid generation function could go here
    // ex: http://stackoverflow.com/a/8809472/188246
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      // use high-precision timer if available
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      /* eslint-disable no-bitwise */
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      /* eslint-enable no-bitwise */
    });
  }

  private enableAjaxLogging(): boolean {
    // Enable ajax remote logging always but on localhost
    return !(environment.activeProfile === 'local');
  }

  private enableConsoleLogging(): boolean {
    // Enable console logging always but on prod
    return !(environment.activeProfile === 'prod');
  }
}
