import * as FileType from 'file-type/browser';

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = () => {
      reject(fileReader.error);
    };

    fileReader.readAsText(file);
  });
}

export async function fromFile(file: Blob): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader: FileReader = new FileReader();

    reader.onload = async () => {
      const buffer: ArrayBuffer = reader.result as ArrayBuffer;
      resolve(buffer);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function getMediaTypeFor(file: File): Promise<string> {
  const blob: Blob = file;
  const detectedFileType = await FileType.fromBlob(blob);
  if (!detectedFileType) {
    throw Error('Unknown file type when extracting media type from file');
  }
  return detectedFileType.mime;
}

export function convert(fileSizeInByte: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (fileSizeInByte === 0) {
    return '0 Byte';
  }
  const factorConverting = Math.floor(Math.log(fileSizeInByte) / Math.log(1024));
  return parseFloat((fileSizeInByte / Math.pow(1024, factorConverting)).toFixed()) + ' ' + sizes[factorConverting];
}
