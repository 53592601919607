<div class="manifest">
  <span
    ><div class="version-item">
      {{ 'manifests.version.firmware' | translate }}
      &nbsp;
      <h5>{{ version.firmwareVersion }}</h5>
    </div>
    <div class="version-item">
      {{ 'manifests.version.appengine' | translate }}
      &nbsp;
      <h5>{{ version.appEngineVersion }}</h5>
    </div></span
  >
  <span *ngIf="version.uuid">
    <h5>{{ version.publisher }}</h5>
    {{ version.date.substring(0, version.date.indexOf('T')) }}</span
  >
  <div class="action-buttons">
    <davinci-button *ngIf="!editMode && version.uuid" (click)="download(version)" type="bare-neutral" icon="action/get_app"
      ><h5>{{ 'manifests.version.manifest' | translate }}</h5></davinci-button
    >
  </div>

  <davinci-button
    *ngIf="editMode"
    type="bare-neutral"
    icon="navigation/cancel"
    (click)="openRemoveManifestVersionDialog(version.uuid)"
  >
  </davinci-button>
</div>
<divider *ngIf="version.documentationResource || editMode"></divider>
<div *ngIf="version.documentationResource || editMode" class="documentation">
  <div>{{ 'manifests.version.docu' | translate }}:</div>
  <davinci-button
    *ngIf="version.documentationResource; else showAddDocu"
    class="button-documentation"
    type="bare-neutral"
    icon="content/content_copy"
    [disabled]="editMode"
    (click)="copy(version.documentationResource.downloadUrl)"
    ><h5>{{ version.documentationResource.name }}</h5></davinci-button
  >
</div>

<ng-template #showAddDocu>
  <davinci-button
    *ngIf="editMode"
    class="button-documentation"
    type="bare-neutral"
    icon="content/add"
    (click)="addDocumentation()"
    ><h5>{{ 'manifests.version.add-docu' | translate }}</h5></davinci-button
  >
</ng-template>
