import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from '@shared/shared.module';

import { ManifestListRoutingModule } from './manifest-list-routing.module';
import { ManifestListComponent } from './manifest-list.component';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    SharedModule,
    ManifestListRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [ManifestListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManifestListModule {}
