import { Component } from '@angular/core';
import { BlockUiService, ManifestService, ToastService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@shared/enums';
import { Device, Manifest, ManifestDetail, ManifestVersionDetail } from '@shared/model';
import { lastValueFrom } from 'rxjs';
const fileSaver = require('file-saver');
const JSZip = require('jszip');

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  private readonly zipFileName = 'manifests.zip';
  private readonly manifestFileExtension = '.mf.xml';
  private manifests: Promise<Manifest[]>;

  constructor(
    private manifestService: ManifestService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private uiBlocker: BlockUiService,
  ) {
    this.manifests = lastValueFrom(this.manifestService.getAllManifests());
  }

  public async downloadManifests() {
    const overviewManifests = await this.manifests;
    this.uiBlocker.enable('admin.downloading');
    const zip = new JSZip();
    await Promise.all(
      overviewManifests.map(async (manifestOverview: any) => {
        const manifestDetail: ManifestDetail = await this.manifestService.getManifestDetails(manifestOverview.uuid);
        await Promise.all(
          manifestDetail.devices.map(async (device) => {
            const folderDeviceType = this.createFolderFor(device, zip);
            await Promise.all(
              manifestDetail.versions.map(async (manifestVersion) => {
                await this.downloadManifestVersionIn(folderDeviceType, manifestVersion);
              }),
            );
          }),
        );
      }),
    );
    zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
      fileSaver.saveAs(content, this.zipFileName);
      this.uiBlocker.disable();
    });
  }

  private createFolderFor(manifest: Device, zip: any): any {
    return zip.folder(manifest.deviceType);
  }

  private async downloadManifestVersionIn(folderName: any, manifest: ManifestVersionDetail) {
    const manifestFileName: string = manifest.firmwareVersion + this.manifestFileExtension;
    try {
      const result = await this.manifestService.getDownloadUrl(manifest.manifestResource.uuid);
      const binary: ArrayBuffer | null = await this.manifestService.downloadManifest(result.presignedResourceUrl);
      if (binary) {
        folderName.file(manifestFileName, binary, { binary: true });
      }
    } catch (error) {
      this.toastService.create({
        title: this.translateService.instant('admin.na.title'),
        message: this.translateService.instant('admin.na.message', { manifestUuid: manifest.uuid }),
        type: ToastType.WARNING,
      });
    }
  }
}
