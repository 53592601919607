import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from '@shared/shared.module';

import { ManifestDetailRoutingModule } from './manifest-detail-routing.module';
import { CreateManifestWizardComponent } from './create/create-manifest-wizard.component';
import { ManifestWizardControlComponent } from './create/manifest-wizard-control/manifest-wizard-control.component';
import { ManifestDetailComponent } from './manifest-detail.component';
import { DevicesComponent } from './devices/devices.component';
import { ManifestsComponent } from './manifests/manifests.component';
import { VersionItemComponent } from './manifests/version-item/version-item.component';
import { ManifestPublishComponent } from './manifest-publish/manifest-publish-button.component';
import { ManifestDetailResolver } from './manifest-detail.resolver';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    SharedModule,
    ManifestDetailRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    ManifestDetailComponent,
    CreateManifestWizardComponent,
    ManifestWizardControlComponent,
    DevicesComponent,
    ManifestsComponent,
    VersionItemComponent,
    ManifestPublishComponent,
  ],
  providers: [ManifestDetailResolver],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManifestDetailModule {}
