<h2 mat-dialog-title>{{ 'dialog.manifest.delete.title' | translate }}</h2>

<mat-dialog-content>
  <p>
    {{ 'dialog.manifest.delete.message-before-manifestname' | translate }}
    <span class="bold">{{ manifest.name }}</span>
    {{ 'dialog.manifest.delete.message-after-manifestname' | translate }}
  </p>
  <p>{{ 'dialog.manifest.delete.info' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <davinci-button type="bare-alt" (click)="cancel()">{{ 'dialog.manifest.delete.cancel' | translate }}</davinci-button>
  <davinci-button type="primary" class="confirm" (click)="deleteManifest()">{{
    'dialog.manifest.delete.delete' | translate
  }}</davinci-button>
</mat-dialog-actions>
