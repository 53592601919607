import { DOMParser } from 'xmldom';
import * as xpath from 'xpath';

export function findByXpath(xmlContent: string, xpathMatcher: string): string | undefined | null {
  const doc: Document = new DOMParser().parseFromString(xmlContent);
  const result: Attr = xpath.select(xpathMatcher, doc, true) as Attr;
  if (result) {
    return result.value || result.textContent;
  }
  return undefined;
}
