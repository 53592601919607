import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManifestDetail } from '@shared/model';

@Component({
  selector: 'delete-manifest-dialog',
  templateUrl: './delete-manifest.dialog.html',
  styleUrls: ['./delete-manifest.dialog.scss'],
})
export class DeleteManifestDialog implements OnInit {
  public manifest: ManifestDetail;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DeleteManifestDialog>) {}

  ngOnInit() {
    this.manifest = this.data.manifest;
  }

  public deleteManifest() {
    this.dialogRef.close(['delete']);
  }

  public cancel() {
    this.dialogRef.close();
  }
}
