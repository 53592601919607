import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, User } from '@core/services';
import { UserRole } from '@shared/enums';

@Injectable()
export class FirmwareDeveloperGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser: User | undefined = this.authService.currentUser();
    return currentUser ? currentUser.hasRole(UserRole.MANIFEST_DEVELOPER) : false;
  }
}
