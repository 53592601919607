import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable()
export class BrowserGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivateChild(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const userAgent = navigator.userAgent.toLowerCase();
    const isChrome = userAgent.indexOf('chrome') > -1;
    const isFirefox = userAgent.indexOf('firefox') > -1;
    const isOpera = userAgent.indexOf('opr') > -1;
    const isSafari = userAgent.indexOf('safari') > -1;
    const isEdge = userAgent.indexOf('edge/17') > -1;
    const isIe = userAgent.indexOf('trident/7') > -1;

    if (isChrome || isFirefox || isOpera || isSafari || isEdge || isIe) {
      return true;
    }

    return this.router.parseUrl('/error/browser');
  }
}
