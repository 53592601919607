import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackService, Logger, ToastService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@shared/enums';
import { Feedback } from '@shared/model';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback.dialog.html',
  styleUrls: ['./feedback.dialog.scss'],
})
export class FeedbackDialog implements AfterViewInit {
  public rating: number = 1;
  public topic: string = 'question';
  public comment: string = '';
  public anonymous: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FeedbackDialog>,
    private changeDetectorRef: ChangeDetectorRef,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private toastService: ToastService,
    private logger: Logger,
  ) {}

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  public ratingChanged(rating: number) {
    this.rating = rating;
  }

  public async submit() {
    const feedback: Feedback = {
      rating: this.rating,
      reason: this.topic,
      description: this.comment,
      anonymous: this.anonymous,
      url: window.location.href,
    };
    this.logger.info(FeedbackDialog, 'Send feedback');
    try {
      await this.feedbackService.sendFeedback(feedback);
      this.toastService.create({
        title: this.translate.instant('feedback.toast.header'),
        message: this.translate.instant('feedback.toast.message'),
        type: ToastType.SUCCESS,
      });
    } catch (err) {
      this.logger.error('SEND_FEEDBACK_ERROR', feedback, err);
    }
    this.dialogRef.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
