import { TableData, RowData } from '@sick-davinci/grid-elements';
import { BehaviorSubject } from 'rxjs';

export class DataTableService {
  public readonly tableModel$: BehaviorSubject<TableData>;
  protected readonly model: TableData;

  public constructor(tableModel: TableData) {
    this.model = tableModel;
    this.tableModel$ = new BehaviorSubject(this.model);
    this.updateModel();
  }

  public clearTable() {
    this.model.content = [];
    this.updateModel();
  }

  public replaceRow(row: RowData, atIndex: number) {
    this.model.content.splice(atIndex, 1, row);
    this.updateModel();
  }

  public addRows(manifests: RowData[], startIdx?: number) {
    this.model.content.splice(startIdx ? startIdx : this.model.content.length, 0, ...manifests);
    this.updateModel();
  }

  public removeRows(rows: RowData[]) {
    rows.forEach((row) => {
      this.model.content.splice(this.getRowIndex(row), 1);
    });
    this.updateModel();
  }

  public getRowIndex(row: RowData): number {
    return this.model.content.indexOf(row);
  }

  public updateModel() {
    this.tableModel$.next({ ...this.model });
  }

  public sortRows(
    byCol: string,
    direction: 'asc' | 'desc' = 'asc',
    customSort?: {
      customAscendingFunc: (first: any, second: any) => number;
      customDescendingFunc: (first: any, second: any) => number;
    },
  ) {
    this.model.content.sort((prev: any, current: any) => {
      if (direction === 'asc') {
        if (customSort) {
          return customSort.customAscendingFunc(prev[byCol], current[byCol]);
        }

        if (prev[byCol] > current[byCol]) {
          return 1;
        }

        if (prev[byCol] < current[byCol]) {
          return -1;
        }

        return 0;
      }

      if (customSort) {
        return customSort.customDescendingFunc(prev[byCol], current[byCol]);
      }

      if (prev[byCol] > current[byCol]) {
        return -1;
      }

      if (prev[byCol] < current[byCol]) {
        return 1;
      }

      return 0;
    });

    this.updateModel();
  }
}
