import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManifestService, ToastService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { AddDocumentationDialog, RemoveManifestVersionDialog } from '@shared/dialogs';
import { ToastType } from '@shared/enums';
import { ManifestVersionDetail } from '@shared/model';
import FileSaver from 'file-saver';

@Component({
  selector: 'version-item',
  templateUrl: './version-item.component.html',
  styleUrls: ['./version-item.component.scss'],
})
export class VersionItemComponent {
  private dialogRefDeleteManifest: MatDialogRef<RemoveManifestVersionDialog>;
  private dialogRefAddDocumentation: MatDialogRef<AddDocumentationDialog>;

  constructor(
    private manifestService: ManifestService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}
  @Input() version: ManifestVersionDetail;
  @Input() editMode: boolean;
  @Output() onDelete: EventEmitter<void> = new EventEmitter();

  public async download(version: ManifestVersionDetail) {
    if (!version.uuid) {
      throw Error('Manifest version uuid is missing');
    }

    const res: {
      presignedResourceUrl: string;
    } = await this.manifestService.getDownloadUrl(version.manifestResource.uuid);

    const binary: ArrayBuffer | null = await this.manifestService.downloadManifest(res.presignedResourceUrl);
    if (binary) {
      FileSaver.saveAs(new Blob([binary]), version.manifestResource.name);
    }
  }

  public async openRemoveManifestVersionDialog(versionUuid: string) {
    this.dialogRefDeleteManifest = this.dialog.open(RemoveManifestVersionDialog, {});
    this.dialogRefDeleteManifest.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.onDelete.emit();
      }
    });
  }

  public addDocumentation() {
    this.dialogRefAddDocumentation = this.dialog.open(AddDocumentationDialog, {});
    this.dialogRefAddDocumentation.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.version.documentationResource = result;
      }
    });
  }

  public copy(url: string) {
    if (!url) {
      throw Error('Documentation url is not defined');
    }
    this.copyToClipboard(url);
    this.toastService.create({
      title: `${this.translate.instant('manifests.version.copy.title')}`,
      message: `${this.translate.instant('manifests.version.copy.message')}`,
      type: ToastType.SUCCESS,
    });
  }

  private copyToClipboard(text: string) {
    const element = document.createElement('textarea');
    element.value = text;
    document.body.appendChild(element);
    element.focus();
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
  }
}
