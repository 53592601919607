<davinci-spinner *ngIf="isLoading; else showManifests" class="loading"></davinci-spinner>
<floating-action-button
  icon="content/add"
  label="{{ 'manifest-list.add' | translate }}"
  [routerLink]="['/manifest/create']"
></floating-action-button>

<ng-template #showManifests>
  <div class="manifests-container" *ngIf="foundManifests.length != 0; else noDeviceManifests">
    <div class="manifest-item" *ngFor="let manifest of foundManifests" (click)="open(manifest)">
      <div class="manifest-item-content">
        <h5>{{ manifest.name }}</h5>
        <p>{{ manifest.publisher }}</p>
      </div>
      <davinci-button
        class="button-edit"
        type="bare-neutral"
        icon="image/edit"
        (click)="openManifestinEditMode($event, manifest)"
      ></davinci-button>
    </div>
  </div>
</ng-template>

<ng-template #noDeviceManifests>
  <davinci-callout class="info" type="info">{{ 'manifest-list.info' | translate }}</davinci-callout>
</ng-template>
