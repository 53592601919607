<div *ngFor="let wizardStep of wizardSteps" class="step">
  <div class="line" [ngClass]="{ done: wizardStep.step <= selectedStep.step }"></div>
  <div
    class="link"
    (click)="jumpTo(wizardStep.step)"
    [ngClass]="{ done: selectedStep.step > wizardStep.step, active: selectedStep.step === wizardStep.step }"
    [attr.data-e2e]="wizardStep.url"
  >
    <div class="circle">
      <span *ngIf="wizardStep.step >= selectedStep.step; else done">{{ wizardStep.step + 1 }}</span>
    </div>
    <div class="title">{{ wizardStep.messageKey | translate }}</div>
  </div>
</div>

<ng-template #done>
  <davinci-icon icon="navigation/check"></davinci-icon>
</ng-template>
