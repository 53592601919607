import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/services';
import { FeedbackDialog } from '@shared/dialogs';

@Component({
  selector: 'sick-footer',
  templateUrl: './sick-footer.component.html',
  styleUrls: ['./sick-footer.component.scss'],
})
export class SickFooterComponent {
  public currentYear;
  public dialogRefFeedback: MatDialogRef<FeedbackDialog>;

  constructor(private dialog: MatDialog, private authService: AuthService) {
    this.currentYear = new Date().getFullYear();
  }

  public openFeedbackDialog() {
    this.dialogRefFeedback = this.dialog.open(FeedbackDialog);
  }

  public async logout(): Promise<void> {
    await this.authService.logOut();
  }
}
