import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(
    @Inject(forwardRef(() => OAuthStorage))
    private oauthStorage: OAuthStorage,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.startsWith(environment.apppoolUrl) ||
      request.url.startsWith(environment.integrationLayerUrl) ||
      request.url.startsWith(environment.deviceManifestUrl)
    ) {
      const token = this.oauthStorage.getItem('access_token');

      if (token) {
        return next.handle(
          request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
            withCredentials: false,
          }),
        );
      }
    }
    return next.handle(request);
  }
}
