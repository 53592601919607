import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateManifestWizardComponent } from './create/create-manifest-wizard.component';
import { ManifestDetailComponent } from './manifest-detail.component';
import { ManifestDetailResolver } from './manifest-detail.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'create', component: CreateManifestWizardComponent, pathMatch: 'full' },
      { path: ':uuid', component: ManifestDetailComponent, resolve: { manifest: ManifestDetailResolver }, pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManifestDetailRoutingModule {}
