import { Injectable } from '@angular/core';
import { BlockUiComponent } from '@shared/components';

@Injectable({
  providedIn: 'root',
})
export class BlockUiService {
  private blockingModule: BlockUiComponent;

  set(modal: BlockUiComponent) {
    this.blockingModule = modal;
  }

  public enable(action?: string) {
    if (this.blockingModule) {
      this.blockingModule.enable(action);
    }
  }

  public disable() {
    if (this.blockingModule) {
      this.blockingModule.disable();
    }
  }

  public setDone() {
    this.blockingModule.done();
    setTimeout(() => {
      this.disable();
    }, 2000);
  }

  public setFailed() {
    this.blockingModule.fail();
    setTimeout(() => {
      this.disable();
    }, 2000);
  }
}
