import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@core/error/error.component';
import { BrowserGuard, FirmwareDeveloperGuard } from '@core/guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [BrowserGuard],
    canActivateChild: [BrowserGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'manifest',
        canActivate: [FirmwareDeveloperGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./manifest-list/manifest-list.module').then((m) => m.ManifestListModule),
          },
          {
            path: '',
            loadChildren: () => import('./manifest-detail/manifest-detail.module').then((m) => m.ManifestDetailModule),
          },
        ],
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [FirmwareDeveloperGuard],
      },
      {
        path: '',
        redirectTo: 'manifest',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'error',
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
      {
        path: ':status',
        component: ErrorComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
