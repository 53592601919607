import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BasicElementsFormLibModule } from '@sick-davinci/basic-elements-ng-form';
import { CustomFormsModule } from 'ngx-custom-validators';

import {
  AppToastNotificationComponent,
  AppToastControllerComponent,
  BlockUiComponent,
  DividerComponent,
  FloatingActionButtonComponent,
  LoadingBarComponent,
  SemverInfoLinkComponent,
} from './components';

import {
  AddDeviceDialog,
  AddDocumentationDialog,
  AddManifestVersionDialog,
  RemoveManifestVersionDialog,
  FeedbackDialog,
  DeleteManifestDialog,
} from './dialogs';

import { SortVersionPipe } from './pipe';

import { NgxStarsModule } from 'ngx-stars';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const exportedModules = [
  AppToastControllerComponent,
  AppToastNotificationComponent,
  BlockUiComponent,
  AddDeviceDialog,
  AddDocumentationDialog,
  AddManifestVersionDialog,
  RemoveManifestVersionDialog,
  DividerComponent,
  FeedbackDialog,
  DeleteManifestDialog,
  FloatingActionButtonComponent,
  LoadingBarComponent,
  SemverInfoLinkComponent,
  SortVersionPipe,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    CustomFormsModule,
    BasicElementsFormLibModule,
    NgxStarsModule,
    ReactiveFormsModule,
    TranslateModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [...exportedModules],
  exports: [CommonModule, FormsModule, CustomFormsModule, RouterModule, BasicElementsFormLibModule, ...exportedModules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
