<div class="content-pane" xmlns="http://www.w3.org/1999/html">
  <davinci-core-header2 class="header" [appName]="appName">
    <davinci-core-header-button id="buttonUser" icon="user"></davinci-core-header-button>
  </davinci-core-header2>
  <davinci-core-navigation type="tree">
    <davinci-core-navigation-item [active]="currentRoute === '/manifest'" (click)="open('manifest')">{{
      'header.menu.manifest' | translate
    }}</davinci-core-navigation-item>
    <davinci-core-navigation-item [active]="currentRoute === '/admin'" (click)="open('admin')">{{
      'header.menu.admin' | translate
    }}</davinci-core-navigation-item>
  </davinci-core-navigation>

  <div class="app-content-view">
    <router-outlet></router-outlet>
  </div>
</div>

<davinci-core-header-popdown autoclose target="#buttonUser">
  <div class="header-account-info">
    <davinci-icon class="header-account-icon" icon="action/account_circle"></davinci-icon>
    <div>
      <h2 class="header-account-info-name">{{ user?.firstName }} {{ user?.lastName }}</h2>
      <p class="header-account-info-email">{{ user?.mailAddress }}</p>
    </div>
  </div>
  <div class="header-account-actions">
    <davinci-button class="header-button" (click)="viewProfile()">{{ 'header.account' | translate }}</davinci-button>
    <davinci-button class="header-button" type="outline" (click)="logout()">{{ 'header.logout' | translate }}</davinci-button>
  </div>
</davinci-core-header-popdown>
