import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManifestListComponent } from './manifest-list.component';

const routes: Routes = [{ path: '', component: ManifestListComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManifestListRoutingModule {}
