import { transition, trigger, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';
import { ResponsiveService, ToastService } from '@core/services';
import { flyInAnimation, flyOutAnimation } from '@shared/animations';
import { AppToastNotificationComponent } from '@shared/components/app-toast-notification/app-toast-notification.component';
import { Toast } from '@shared/model';

@Component({
  selector: 'app-toast-controller',
  templateUrl: './app-toast-controller.component.html',
  styleUrls: ['./app-toast-controller.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [useAnimation(flyInAnimation)]),
      transition(':leave', [useAnimation(flyOutAnimation)]),
    ]),
  ],
})
export class AppToastControllerComponent {
  public toasts: AppToastNotificationComponent[];
  public callbackFunction: () => void;

  constructor(private toastService: ToastService, private responsiveService: ResponsiveService) {
    this.toasts = [];
    this.toastService.toastsChanged().subscribe((toast) => this.createToast(toast));
  }

  public createToast(toast: Toast) {
    const newToast = new AppToastNotificationComponent(this.responsiveService);
    newToast.title = toast.title;
    newToast.message = toast.message;
    newToast.type = toast.type;
    newToast.labelButton = toast.labelButton;
    newToast.timeout = toast.timeout;
    newToast.id = Math.random();

    if (toast.callback) {
      this.callbackFunction = toast.callback;
    }
    this.toasts.unshift(newToast);
  }

  public deleteToast(id: number) {
    for (const toast of this.toasts) {
      if (toast.id === id) {
        this.toasts.splice(this.toasts.indexOf(toast), 1);
        break;
      }
    }
  }

  public clickedActionButton(id: number) {
    if (this.callbackFunction) {
      this.callbackFunction();
    }
    this.deleteToast(id);
  }
}
