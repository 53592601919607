import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { BasicElementsFormLibModule } from '@sick-davinci/basic-elements-ng-form';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ErrorComponent, CustomErrorHandler } from '@core/error';
import { SickHeaderComponent, SickFooterComponent } from '@core/components';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';

import { App } from './app.component';
import { AppRoutingModule } from './app-routing.module';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  bootstrap: [App],
  declarations: [ErrorComponent, App, SickHeaderComponent, SickFooterComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BasicElementsFormLibModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        backdropClass: 'dialog-overlay-background',
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
