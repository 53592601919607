// import { Environment } from '@shared/model';
import { Environment } from '../app/shared/model/enviroment.model';

export const environment: Environment = {
  activeProfile: 'int',
  production: false,
  logLevelConsole: 'debug',
  logLevelAjax: 'info',

  apppoolUrl: 'https://api.apppool.uat-cloud.sick.com/apppool',
  deviceManifestUrl: 'https://api.apppool.uat-cloud.sick.com/device-manifest-service',
  integrationLayerUrl: 'https://api.int.sickag.cloud/integration',
  apppoolApiKey: 'AgRjwJ7gOM9JbHlEjKMMC126xwrG9UZ4a9mIQrM3',
  deviceManifestApiKey: 'DPJNJNDkdi8fjdnbfPwhhdcjdh72dnsada8D8023',

  oidc: {
    issuer: 'https://id.uat.sick.com/auth/realms/sickservices',
    clientId: 'DeviceManifest',
  },
  oidcUrlPasswordChange: 'https://id.uat.sick.com/auth/realms/sickservices/account/password',
  oidcUrlProfile: 'https://id.uat.sick.com/auth/realms/sickservices/account/',
  inactivityTimeoutInMinutes: 120,
  sickUrl: 'https://www.sick.com/',
};
