import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Logger, ResponsiveService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class App implements OnInit {
  constructor(
    private router: Router,
    private logger: Logger,
    private translate: TranslateService,
    private responsiveService: ResponsiveService,
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.responsiveService.setScreenWidth(window.innerWidth);
  }

  public ngOnInit() {
    this.logger.debug('Starting application Device Manifest');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });
    this.onResize();
  }
}
