import { DropDown } from '@sick-davinci/basic-elements';
import { Component, html, Prop, TemplateResult } from '@sick-davinci/ce-decorators';

/**
 * WORKAROUND FOR DAVINCI
 * until DAVIBE-412 is implemented in basic elements
 * A custom value renderer is needed to display a selected object.
 */
@Component({
  inheritStyle: true,
  tag: 'davinci-enhanced-drop-down',
})
export class EnhancedDropdown extends DropDown {
  @Prop({ reflectAsAttribute: false })
  valueRenderer: (value: string | {}) => string;

  constructor() {
    super();
    this.valueRenderer = defaultValueRenderer;
  }

  protected renderInput(): TemplateResult {
    return html`
      <input class="input"
        .placeholder="${this.placeholder}"
        type="text"
        .value="${this.valueRenderer(this.internalValue ? this.internalValue : '')}"
        @blur="${this.onblur}"
        @focus="${this.onFocus}"
        @input="${this.onInputInput}"
        @change="${this.onInputChange}"
        @keydown="${this.onInputKeyDown}">
      </input>
      <div class="currentvalue">${
        this.selectedDataEntry
          ? this.contentRenderer(<string>this.selectedDataEntry.value, this.selectedDataEntry.label)
          : this.placeholder
      }</div>
      <davinci-spinner class="spinner"></davinci-spinner>
    `;
  }
}

export const defaultValueRenderer = (value: string | object) => value as string;
