import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUiService, Logger, ManifestService } from '@core/services';
import { DeleteManifestDialog } from '@shared/dialogs';
import { ManifestDetail } from '@shared/model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'manifest-detail',
  templateUrl: './manifest-detail.component.html',
  styleUrls: ['./manifest-detail.component.scss'],
})
export class ManifestDetailComponent implements OnInit {
  public editMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public manifest: ManifestDetail;
  public manifestGhost: ManifestDetail;

  private dialogRefDeleteManifestComponent: MatDialogRef<DeleteManifestDialog>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manifestService: ManifestService,
    private dialog: MatDialog,
    private logger: Logger,
    private uiBlocker: BlockUiService,
  ) {}

  ngOnInit(): void {
    this.editMode$.next(this.getQueryParameterValueFor(this.route, 'editMode'));
    this.manifest = this.route.snapshot.data['manifest'];
    this.manifestGhost = this.getNewDataObjectFrom(this.manifest);
  }

  public goToManifestListPage(): void {
    this.router.navigate(['manifest']);
  }

  public toggleEditMode(): void {
    this.editMode$.next(!this.editMode$.value);
  }

  public onPublishingFinished() {
    this.manifestGhost = this.getNewDataObjectFrom(this.manifest);
    this.toggleEditMode();
  }

  public discardChanges(): void {
    this.manifest = this.getNewDataObjectFrom(this.manifestGhost);
    this.toggleEditMode();
  }

  public async openDeleteManifestDialogFor(manifest: ManifestDetail): Promise<void> {
    this.dialogRefDeleteManifestComponent = this.dialog.open(DeleteManifestDialog, { data: { manifest: manifest } });
    this.dialogRefDeleteManifestComponent.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.deleteManifestComponent(manifest.uuid);
      }
    });
  }

  private async deleteManifestComponent(manifestComponent: string): Promise<void> {
    try {
      this.uiBlocker.enable('manifest-detail.publish');
      await this.manifestService.deleteManifest(<string>manifestComponent);
      this.goToManifestListPage();
    } catch (err) {
      this.logger.error('DELETE_MANIFEST', { uuid: manifestComponent }, err);
      throw err;
    } finally {
      this.uiBlocker.disable();
    }
  }

  private getNewDataObjectFrom(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  private getQueryParameterValueFor(route: ActivatedRoute, parameterIdentifier: string): boolean {
    const queryParameterValue: string | null = route.snapshot.queryParamMap.get(parameterIdentifier);
    const value: boolean = queryParameterValue ? queryParameterValue.toLocaleLowerCase() === 'true' : false;
    return value;
  }
}
