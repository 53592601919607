import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiKey = this.getApiKeyFor(request.url);

    if (apiKey) {
      request = request.clone({
        headers: request.headers.set('x-api-key', apiKey),
        withCredentials: false,
      });
    }

    return next.handle(request);
  }

  private getApiKeyFor(url: string): string | null {
    if (url.startsWith(environment.apppoolUrl) || url.startsWith(environment.integrationLayerUrl)) {
      return environment.apppoolApiKey;
    }

    if (url.startsWith(environment.deviceManifestUrl)) {
      return environment.deviceManifestApiKey;
    }

    return null;
  }
}
