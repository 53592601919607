import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, User } from '@core/services';
import '@sick-davinci/core-elements/lib';
import '@sick-davinci/core-elements/lib/header/Header';
import '@sick-davinci/core-elements/lib/navigation/Navigation';
import { environment } from 'environments/environment';

@Component({
  selector: 'sick-header',
  templateUrl: './sick-header.component.html',
  styleUrls: ['./sick-header.component.scss'],
})
export class SickHeaderComponent {
  public appName: string = 'DeviceManifest';
  public user: User | undefined;
  public currentRoute: string;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.updateCurrentRouteWhenChanged();
    this.user = this.authService.currentUser();
  }

  public viewProfile(): void {
    this.openExternalLink(environment.oidcUrlProfile);
  }

  public async logout(): Promise<void> {
    await this.authService.logOut();
  }

  public open(route: string) {
    this.router.navigate([route]);
  }

  private openExternalLink(url: string) {
    document.location.href = url;
  }

  private updateCurrentRouteWhenChanged() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
}
