import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.scss'],
})
export class FloatingActionButtonComponent {
  @Input() icon: string;

  @Input() label: string;

  @Input() type: string;

  @Input() disabled: boolean;

  @Output() click = new EventEmitter();

  constructor() {}

  public clicked() {
    this.click.emit();
  }
}
