<h2 mat-dialog-title>{{ 'dialog.docu.add.title' | translate }}</h2>

<mat-dialog-content class="content">
  <davinci-spinner *ngIf="isLoading" class="loading"></davinci-spinner>
  <div class="container-documentation" *ngIf="documentation; else showFileChooser">
    <div class="header">
      <davinci-icon icon="action/description" class="icon-file"></davinci-icon>
      <h5 class="document-title">{{ documentation.name }}</h5>
    </div>
    <h6 class="document-size">{{ convertedFileSize }}</h6>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-area">
  <davinci-button class="button" type="bare-alt" (click)="cancel()">{{ 'dialog.docu.add.cancel' | translate }}</davinci-button>
  <davinci-button type="primary" class="confirm button" (click)="addDocumentation()" [disabled]="!documentation">
    {{ 'dialog.docu.add.add' | translate }}
  </davinci-button>
</mat-dialog-actions>

<ng-template #showFileChooser>
  <davinci-dnd-file-chooser
    class="file-chooser"
    labelDrop="{{ 'dialog.docu.add.drop' | translate }}"
    labelBrowse="{{ 'dialog.docu.add.browse' | translate }}"
    accept=".zip,.rar"
    (change)="selectDocumentation($event.detail)"
  >
  </davinci-dnd-file-chooser>
  <davinci-status-text *ngIf="errorMessageSelectedFile" error>{{ errorMessageSelectedFile }}</davinci-status-text>
</ng-template>
