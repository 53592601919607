<h2 mat-dialog-title>{{ 'dialog.feedback.title' | translate }}</h2>

<mat-dialog-content>
  <h4 class="subtitle">{{ 'dialog.feedback.subtitle' | translate }}</h4>

  <div class="container-rating">
    <ngx-stars [color]="'#007CC1'" [wholeStars]="true" [size]="2" (ratingOutput)="ratingChanged($event)"></ngx-stars>
  </div>

  <davinci-toggle-group class="container-topic" [(ngModel)]="topic">
    <davinci-radio-button class="topic" value="question">{{ 'dialog.feedback.topic.question' | translate }}</davinci-radio-button>
    <davinci-radio-button class="topic" value="bug">{{ 'dialog.feedback.topic.bug' | translate }}</davinci-radio-button>
    <davinci-radio-button class="topic" value="idea">{{ 'dialog.feedback.topic.idea' | translate }}</davinci-radio-button>
    <davinci-radio-button class="topic" value="other">{{ 'dialog.feedback.topic.other' | translate }}</davinci-radio-button>
  </davinci-toggle-group>

  <div class="container-comment">
    <davinci-text-area
      class="comment"
      label="{{ 'dialog.feedback.description' | translate }}"
      multiline
      [(ngModel)]="comment"
      #commentModel="ngModel"
      required
      [attr.error]="commentModel.errors"
    >
    </davinci-text-area>
    <davinci-status-text error [hidden]="!commentModel.errors">{{ 'dialog.feedback.required' | translate }}</davinci-status-text>
  </div>

  <davinci-checkbox class="anonymous" [(ngModel)]="anonymous">{{ 'dialog.feedback.anonymous' | translate }}</davinci-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <davinci-button class="button" type="bare-alt" (click)="close()">{{ 'dialog.feedback.cancel' | translate }}</davinci-button>
  <davinci-button class="button confirm" type="primary" [disabled]="commentModel.errors" (click)="submit()">
    {{ 'dialog.feedback.submit' | translate }}
  </davinci-button>
</mat-dialog-actions>
