import { Component, OnInit } from '@angular/core';
import { HttpBusyService } from '@core/services';

@Component({
  selector: 'loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit {
  public visible = false;

  constructor(private httpBusy: HttpBusyService) {}

  ngOnInit() {
    this.httpBusy.observable().subscribe((status) => {
      this.visible = status;
    });
  }
}
