import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ProductsQueryResult, ProductListItem } from '@shared/model';
import { lastValueFrom } from 'rxjs';

export interface ProductsSearchResult {
  results: {
    Product: ProductListItem[];
  };
  currentPage: number;
  resultsPerPage: number;
  total: number;
  pageCount: number;
}

@Injectable()
export class IntegrationLayerService {
  private serviceUrl: string;

  constructor(private httpClient: HttpClient) {
    this.serviceUrl = environment.integrationLayerUrl;
  }

  public async getProducts(searchValue: string): Promise<ProductListItem[]> {
    const localization = 'deAG-sick';
    const productsQueryResult: ProductsQueryResult = await lastValueFrom(
      this.httpClient.get<ProductsQueryResult>(`${this.serviceUrl}/products`, {
        params: { query: searchValue, localization },
      }),
    );
    return productsQueryResult.Product.filter((e) => e.Name && e.PartNumber);
  }
}
