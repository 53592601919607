<div class="application-based">
  <a class="link" href="https://supportportal.sick.com/" target="_blank">{{ 'footer.support' | translate }}</a>
  <div class="link" data-e2e="openFeedback" (click)="openFeedbackDialog()">{{ 'footer.provide-feedback' | translate }}</div>
</div>

<div class="legal-section">
  <div class="legal-section-links">
    <a class="link" href="https://www.sick.com/de/en/imprint/w/imprint/" target="_blank"> {{ 'footer.imprint' | translate }}</a>
    <div class="splitter">|</div>
    <a class="link" href="https://www.sick.com/de/en/general-terms-and-conditions/w/tac/" target="_blank">{{
      'footer.terms-and-conditions' | translate
    }}</a>
    <div class="splitter">|</div>
    <a class="link" href="https://www.sick.com/de/en/sick-data-privacy-declaration/w/dataprotection/" target="_blank">{{
      'footer.data-protection' | translate
    }}</a>
    <div class="splitter">|</div>
    <a class="link" href="/assets/licenses.txt" target="_blank">{{ 'footer.third-party-licenses' | translate }}</a>
    <div class="container-logout">
      <div class="splitter">|</div>
      <a class="link" (click)="logout()">{{ 'header.logout' | translate }}</a>
    </div>
  </div>
  <div class="text copyright">© {{ currentYear }} SICK AG</div>
</div>
