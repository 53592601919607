<h2 mat-dialog-title>{{ 'dialog.manifest-version.delete.title' | translate }}</h2>

<mat-dialog-content>
  <p>{{ 'dialog.manifest-version.delete.message' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <davinci-button type="bare-alt" (click)="cancel()">{{ 'dialog.manifest-version.delete.cancel' | translate }}</davinci-button>
  <davinci-button type="primary" class="confirm" (click)="deleteVersion()">{{
    'dialog.manifest-version.delete.remove' | translate
  }}</davinci-button>
</mat-dialog-actions>
