import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { BasicElementsFormLibModule } from '@sick-davinci/basic-elements-ng-form';

import { AuthorizeInterceptor, RequestInterceptor, TrackingInterceptor } from '@core/interceptors';
import {
  AuthService,
  BlockUiService,
  FeedbackService,
  HttpBusyService,
  Logger,
  ManifestService,
  IntegrationLayerService,
} from '@core/services';
import { HttpErrorTranslation } from '@core/translation/http-error-translation.service';
import { BrowserGuard, FirmwareDeveloperGuard } from '@core/guards';

import { OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';

export function appAuthServiceFactory(initializeService: AuthService) {
  return async () => initializeService.initialize(environment);
}

export function loggerFactory() {
  return new Logger();
}

@NgModule({
  imports: [BasicElementsFormLibModule, BrowserAnimationsModule, CommonModule, HttpClientModule, MatDialogModule, RouterModule],
  providers: [
    BrowserGuard,
    FirmwareDeveloperGuard,
    AuthService,
    BlockUiService,
    FeedbackService,
    HttpBusyService,
    HttpErrorTranslation,
    ManifestService,
    IntegrationLayerService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appAuthServiceFactory,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrackingInterceptor,
      multi: true,
    },
    {
      provide: Logger,
      useFactory: loggerFactory,
    },
    // Workaround for known issue on old Edge browsers to log in on 'localhost'
    // See https://textslashplain.com/2020/01/30/security-zones-in-edge/
    {
      provide: OAuthStorage,
      useFactory: () =>
        window.location.hostname === 'localhost' && window.navigator.userAgent.includes('Edge') ? localStorage : sessionStorage,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
