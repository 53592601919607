import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpErrorTranslation {
  public constructor(private translate: TranslateService) {}

  public tryTranslateError(error: any): { errorTitle: string; errorDescription: string } | null {
    if (error instanceof HttpErrorResponse) {
      const httpError: HttpErrorResponse = error as HttpErrorResponse;
      let status = httpError.status;
      const translationPath: string = `error.${status}.title`;
      let errorTitle: string = this.translate.instant(translationPath);
      const undefinedTranslationPath: boolean = errorTitle === translationPath;

      if (undefinedTranslationPath) {
        status = 500;
        errorTitle = this.translate.instant(`error.${status}.title`);
      }

      const errorDescription = this.translate.instant(`error.${status}.message`);

      return { errorTitle, errorDescription };
    }
    return null;
  }
}
