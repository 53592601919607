<div class="container">
  <div class="content">
    <davinci-link class="link-return" icon="hardware/keyboard_arrow_left" [routerLink]="['/manifest']">{{
      'manifest-create.back' | translate
    }}</davinci-link>

    <div class="wizard-content">
      <manifest-wizard-control [wizardSteps]="wizardSteps" [selectedStep]="selectedStep"></manifest-wizard-control>

      <h4>{{ selectedStep.messageKey | translate }}</h4>
      <divider class="divider-step-name"></divider>

      <div *ngIf="selectedStep.step === 0">
        <davinci-callout class="info">{{ 'manifest-create.name.info' | translate }}</davinci-callout>
        <davinci-text-field
          type="text"
          label="{{ 'manifest-create.name.label' | translate }}"
          [(ngModel)]="manifest.name"
          #manifestName="ngModel"
          required
        >
        </davinci-text-field>
        <status-text error [hidden]="!manifestName?.errors">{{ 'manifest-create.name.required' | translate }}</status-text>
      </div>

      <devices class="devices" *ngIf="selectedStep.step === 1" [manifest]="manifest" editMode="true"></devices>

      <manifests class="manifests" *ngIf="selectedStep.step === 2" [manifest]="manifest" editMode="true"></manifests>

      <divider class="divider-action"></divider>
      <div class="action">
        <davinci-button
          class="button"
          [ngClass]="{ 'button-previous': selectedStep.step == 0 }"
          [disabled]="selectedStep.step == 0"
          type="bare-alt"
          data-e2e="buttonPrevious"
          (click)="jumpTo(selectedStep.step - 1)"
          >{{ 'manifest-create.previous' | translate }}</davinci-button
        >
        <davinci-button
          *ngIf="selectedStep.step < wizardSteps.length - 1; else publish"
          class="button"
          [ngClass]="{ 'button-next': selectedStep.step > 0 }"
          [disabled]="(selectedStep.step == 0 && !manifest.name) || (selectedStep.step == 1 && manifest.devices.length === 0)"
          type="primary"
          data-e2e="buttonNext"
          (click)="jumpTo(selectedStep.step + 1)"
          >{{ 'manifest-create.next' | translate }}</davinci-button
        >
      </div>
    </div>
  </div>
</div>

<ng-template #publish>
  <manifest-publish-button class="button publish" [manifest]="manifest" [manifestGhost]="manifestGhost"></manifest-publish-button>
</ng-template>
