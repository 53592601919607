import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ManifestService, Logger } from '@core/services';
import { ManifestDetail } from '@shared/model';

@Injectable()
export class ManifestDetailResolver implements Resolve<ManifestDetail> {
  private timer: NodeJS.Timeout;
  constructor(private manifestservice: ManifestService, private logger: Logger) {}

  public resolve(route: ActivatedRouteSnapshot): Promise<ManifestDetail> {
    const uuid = route.params.uuid;

    const details = this.manifestservice
      .getManifestDetails(uuid)
      .catch((err) => {
        this.logger.error('LOAD_MANIFESTS_ERROR', {}, err);
        throw err;
      })
      .finally(() => {
        if (this.timer) {
          window.clearTimeout(this.timer);
        }
      });

    return details;
  }
}
