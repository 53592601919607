<davinci-callout type="info" *ngIf="!editMode && manifest?.devices.length == 0">{{ 'devices.na' | translate }}</davinci-callout>
<div class="container-tags" *ngIf="!editMode; else editTags">
  <davinci-tag *ngFor="let device of manifest.devices">
    <ng-template [ngTemplateOutlet]="contentTag" [ngTemplateOutletContext]="{ device: device }"></ng-template>
  </davinci-tag>
</div>

<ng-template #editTags>
  <div class="container-tags-editmode">
    <div class="tags">
      <davinci-tag type="filter" *ngFor="let device of manifest.devices" (remove)="removeDevice(device)">
        <ng-template [ngTemplateOutlet]="contentTag" [ngTemplateOutletContext]="{ device: device }"></ng-template>
      </davinci-tag>
    </div>
    <davinci-button type="bare" icon="content/add" (click)="openAddDeviceDialog()">{{
      'devices.add' | translate
    }}</davinci-button>
  </div>
</ng-template>

<ng-template #contentTag let-device="device">
  <p>
    <b>{{ device.deviceType }}</b> ({{ device.partNumber }})
  </p>
  <p>{{ 'devices.product-family' | translate }}: {{ device.productFamily }}</p>
</ng-template>
