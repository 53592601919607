import { Pipe, PipeTransform } from '@angular/core';
import { UnpublishedManifestVersion } from '@shared/model';
const semver = require('semver');

@Pipe({ name: 'sortByFirmwareVersion', pure: false })
export class SortVersionPipe implements PipeTransform {
  transform(versions: UnpublishedManifestVersion[]): UnpublishedManifestVersion[] {
    return versions.sort((v1: UnpublishedManifestVersion, v2: UnpublishedManifestVersion) => {
      const firmwareVersion1 = this.sanitizeVersion(v1.firmwareVersion);
      const firmwareVersion2 = this.sanitizeVersion(v2.firmwareVersion);
      if (semver.lt(firmwareVersion1, firmwareVersion2)) {
        return 1;
      } else if (semver.gt(firmwareVersion1, firmwareVersion2)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  private sanitizeVersion(version: string): string {
    return semver.valid(semver.coerce(version));
  }
}
