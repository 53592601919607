<div class="container" [ngClass]="type">
  <div class="header">
    <davinci-icon [icon]="iconPath" class="icon-status"></davinci-icon>
    <p class="title" [ngClass]="{ s: screenWidth < 600 }">{{ title }}</p>
    <davinci-icon icon="navigation/close" class="icon-close" (click)="closeToast()"></davinci-icon>
  </div>
  <div class="body">
    <p class="message" [ngClass]="{ s: screenWidth < 600 }" [innerHTML]="message"></p>
  </div>
  <div *ngIf="labelButton" class="action">
    <davinci-button class="action-button" type="bare-neutral" (click)="actionButtonClicked()">{{ labelButton }}</davinci-button>
  </div>
</div>
