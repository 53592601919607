import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger, ToastService } from '@core/services';
import { ToastType } from '@shared/enums';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private logger: Logger,
    private translateService: TranslateService,
    private toastService: ToastService,
    private injector: Injector,
    private zone: NgZone,
  ) {}

  public handleError(error: any) {
    this.logger.error('UNHANDLED_FRONTEND_ERROR', {}, error);
    const router: Router = this.injector.get(Router);
    if (error.rejection instanceof HttpErrorResponse) {
      const httpError = error.rejection;
      if (httpError) {
        if (httpError.status === 403) {
          this.toastService.create({
            title: `${httpError.status} - ${httpError.statusText}`,
            message: this.translateService.instant(httpError.error.message, { url: httpError.url }),
            type: ToastType.ERROR,
          });
        }

        this.zone.run(() => {
          setTimeout(() => {
            router.navigate(['error', httpError.status], { skipLocationChange: true });
          }, 0);
        });
      }
    } else {
      this.zone.run(() => {
        setTimeout(() => {
          router.navigate(['error'], { skipLocationChange: true });
        }, 0);
      });
    }
  }
}
