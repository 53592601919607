<div class="container">
  <div class="sidebar">
    <div class="info">
      <b>{{ 'manifest-detail.name' | translate }}:</b>&nbsp;{{ manifest.name }}
    </div>
    <div class="info">
      <b>{{ 'manifest-detail.publisher' | translate }}:</b>&nbsp;{{ manifest.publisher }}
    </div>
  </div>
  <div class="content">
    <davinci-link class="link-return" icon="hardware/keyboard_arrow_left" (click)="goToManifestListPage()">{{
      'manifest-detail.back' | translate
    }}</davinci-link>

    <div class="cover" [ngClass]="{ editMode: editMode$ | async }">
      <h2 *ngIf="!(editMode$ | async); else editTitle">{{ manifest.name }}</h2>
      <div class="actions">
        <davinci-button
          *ngIf="!(editMode$ | async); else showPublishButtons"
          class="button"
          type="bare"
          icon="image/edit"
          (click)="toggleEditMode()"
          >{{ 'manifest-detail.edit' | translate }}</davinci-button
        >
        <davinci-button
          *ngIf="!(editMode$ | async)"
          class="button"
          type="bare"
          icon="action/delete"
          (click)="openDeleteManifestDialogFor(manifest)"
          >{{ 'manifest-detail.delete' | translate }}</davinci-button
        >
      </div>
    </div>
    <h4 class="subtitle">{{ 'manifest-detail.devices' | translate }}</h4>
    <devices class="devices" [manifest]="manifest" [editMode]="editMode$ | async"></devices>

    <h4 class="subtitle">{{ 'manifest-detail.versions' | translate }}</h4>
    <manifests class="manifests" [manifest]="manifest" [editMode]="editMode$ | async"></manifests>
  </div>
</div>

<ng-template #editTitle>
  <div class="container-edit-name">
    <davinci-text-field
      class="name"
      type="text"
      label="{{ 'manifest-detail.manifest-name' | translate }}"
      [(ngModel)]="manifest.name"
      #manifestName="ngModel"
      required
    >
    </davinci-text-field>
    <status-text error [hidden]="!manifestName?.errors">{{ 'manifest-detail.name-required' | translate }}</status-text>
  </div>
</ng-template>

<ng-template #showPublishButtons>
  <div class="actions-publish">
    <manifest-publish-button
      class="button publish"
      [manifest]="manifest"
      [manifestGhost]="manifestGhost"
      (publishedFinished)="onPublishingFinished()"
    ></manifest-publish-button>
    <davinci-link type="bare" class="button" (click)="discardChanges()">{{ 'manifest-detail.discard' | translate }}</davinci-link>
  </div>
</ng-template>
