import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styleUrls: ['./error.component.scss'],
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  public status: string | null;
  public errorTitle: string;
  public errorMessage: string;

  constructor(private route: ActivatedRoute, private translationService: TranslateService, private location: Location) {}

  public async ngOnInit() {
    this.status = this.route.snapshot.paramMap.get('status');
    if (this.status) {
      const translationPath: string = `error.${this.status}.title`;
      const undefinedTranslationPath: boolean =
        (await this.translationService.get(translationPath).toPromise()) === translationPath;
      if (undefinedTranslationPath) {
        this.status = '500';
      }
      this.errorTitle = await this.translationService.get(`error.${this.status}.title`).toPromise();
      this.errorMessage = await this.translationService.get(`error.${this.status}.message`).toPromise();
    } else {
      this.errorTitle = await this.translationService.get('error.frontend.unhandledErrorTitle').toPromise();
      this.errorMessage = await this.translationService.get('error.frontend.unhandledErrorMessage').toPromise();
    }
  }

  public goBack() {
    this.location.back();
  }
}
